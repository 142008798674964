import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";

const Freewebinar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.fbq("track", "Lead");
    window.location.replace('https://us06web.zoom.us/webinar/register/WN_rIAvQAimTQqLQpoSLnFm0A')
  }, []);

  return (
    <>
      <section>
        <div className="container">
          <div className="mt-5">
            <div className="alignment">
              <img src={logo} alt="Social Eagle" className="calenderlogo" />
            </div>
            <div className="mt-5">
              <iframe
                className="Zoomstyle"
                title="Zoom"
                src="https://us06web.zoom.us/webinar/register/2617029977245/WN_CL_bpO3yT7664xlFct4Hbw"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Freewebinar;
