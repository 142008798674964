import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";
import ReactPlayer from "react-player";

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.fbq("track", "Lead");
  }, []);

  return (
    <>
      <div className="container">
        <section className="mt-5">
          <div className="alignment">
            <img src={logo} alt="Social Social" className="logo" />
          </div>
        </section>
        <section className="mt-5">
          <div className="alignment">
            <h6 className="thankstitle">"CONGRATULATIONS"</h6>
            <p className="thankstitle1">
              You are an&nbsp;
              <span className="freecall">ACTION TAKER</span>
              <br />
              <span className="thankssubtitle">(Fortune Favours The Bold)</span>
            </p>
            <p className="thankstitle1">
              You Are One Step Away From Changing Your Life Forever
            </p>
            <div className="my-5">
              <ReactPlayer
                url="https://socialeagle.wistia.com/medias/jlxwqvhbo1"
                className="videoalignment"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Thankyou;
