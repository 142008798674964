import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "../Css/Style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import payment from "../Assets/payment.jpg";

const MakeAdvance = () => {

  const currentDate = new Date();
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  const [percenteage, setPercenteage] = useState(0);
  const [inputIndex, setInputIndex] = useState(1);
  const [create, SetCreate] = useState({});
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [nameErrorMSG, setNameErrorMSG] = useState('');
  const [phoneErrorMSG, setPhoneErrorMSG] = useState('');
  const [whatAppErrorMSG, setWhatAppErrorMSG] = useState('');
  const [emailErrorMSG, setEmailErrorMSG] = useState('');
  const [citryErrorMSG, setCityErrorMSG] = useState('');
  const [mofpMSG,setmofpMSG] = useState('');
  const [showQR, setShowQR] = useState(false);

  const validate = async () => {
    let flag = true;
    setLoading(true);
    setNameErrorMSG("");
    setPhoneErrorMSG("");
    setWhatAppErrorMSG("");
    setEmailErrorMSG("");
    setCityErrorMSG("");
    setmofpMSG("");
    if (!create.name?.length > 0) {
      flag = false;
      setNameErrorMSG("Name is Required");
    }
    if (!(create.phoneNumber?.length >=9)) {
      flag = false;
      setPhoneErrorMSG("Phone Number is Required");
    }
    if (!(create.whatApp?.length >=9)) {
      flag = false;
      setWhatAppErrorMSG("What's App Number is Required");
    }
    if (!create.emailId?.length > 0) {
      flag = false;
      setEmailErrorMSG("Email is Required");
    }
    if (!create.city?.length > 0) {
      flag = false;
      setCityErrorMSG("City is Required");
    }

    if (!create.modeofpayment?.length > 0) {
      flag = false;
      setmofpMSG("Mode of Payment is Required");
    }

    
    if (flag) {
      try {
        let inputValue = {
          "Name": create.name,
          "Phone Number": create.phoneNumber,
          "What's App Number": create.whatApp,
          "Email Id": create.emailId,
          "City": create.city,
          "Date": currentDate.toDateString(),
          "Mode of Payment":create.modeofpayment
        }
        var form_data = new FormData();
        for (var key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        const response = await fetch(
          'https://script.google.com/macros/s/AKfycbySgomUUhFcPsnzj9tyqAVV3_77SL2cb862KLiV00N1TSwFBks81Mdg2e1PH2nA1n2sxQ/exec', // Replace with the URL from the deployed web app
          {
            method: 'POST',

            body: form_data,
          }
        );

        const result = await response.text();
        setLoading(false);
        // console.log(result);
        setShowQR(true);
      } catch (error) {
        setLoading(false);
        console.error('Error writing to Google Sheets:', error);
      }
    }else{
      setLoading(false);
    }
  }

  const setData = (e, key) => {
    SetCreate({ ...create, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div className="make-advance">
      <div className="make-advance-header-height">
        <Header />
      </div>
      <div style={{ color: 'white', backgroundColor: '#6c1213', textAlign: 'center' }}>
        <h2>Payment form Registration</h2>
      </div>
      <div className="make-advance-container">
        {


          !showQR ?
            <div className="form" data-aos="zoom-in" data-aos-duration="1500">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-item">
                    <input type="text" autoComplete="off" onChange={(e) => { setData(e.target.value, "name"); }} />
                    <label for="text">Name</label>
                  </div>
                  <label style={{ color: "#6c1213", marginBottom: "20px" }}>{nameErrorMSG}</label>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-item">
                    <input type="text" autoComplete="off" onChange={(e) => { setData(e.target.value, "phoneNumber"); }} />
                    <label for="text">Phone Number</label>
                  </div>
                  <label style={{ color: "#6c1213", marginBottom: "20px" }}>{phoneErrorMSG}</label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-item">
                    <input type="text" autoComplete="off" onChange={(e) => { setData(e.target.value, "whatApp"); }} />
                    <label for="text">What's App Number</label>
                  </div>
                  <label style={{ color: "#6c1213", marginBottom: "20px" }}>{whatAppErrorMSG}</label>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-item">
                    <input type="text" autoComplete="off" onChange={(e) => { setData(e.target.value, "emailId"); }} />
                    <label for="text">Email Id</label>
                  </div>
                  <label style={{ color: "#6c1213", marginBottom: "20px" }}>{emailErrorMSG}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-item">
                    <input type="text" autoComplete="off" onChange={(e) => { setData(e.target.value, "city"); }} />
                    <label for="text">City</label>
                  </div>
                  <label style={{ color: "#6c1213", marginBottom: "20px" }}>{citryErrorMSG}</label>
                </div>
                <div className="col-md-6 col-sm-12">
                <div className="form-item">
                  <select className="form-control form-control-select" onChange={(e) => { setData(e.target.value, "modeofpayment"); }}>
                      <option value="" className="form-control">Select</option>
                      <option value="Credit Card" className="form-control">Credit Card</option>
                      <option value="Gpay" className="form-control">Gpay</option>
                      <option value="Phonepe" className="form-control">Phonepe</option>
                      <option value="Scan QR code" className="form-control">Scan QR code</option>
                      <option value="UPI Payment" className="form-control">UPI Payment</option>
                  </select>
                  <label for="text">Mode of Payment</label>
                  </div>
                  <label style={{ color: "#6c1213", marginBottom: "20px" }}>{mofpMSG}</label>
                </div>
              </div>






              <div style={{ textAlign: "right" }}>
                <button type="submit" className="ma-next-button " onClick={() => validate(inputIndex)}>

                  {loading ? (
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
            :
            <center><img src={payment} width="60%" /></center>
        }
      </div>
    </div>

  );
};

export default MakeAdvance;
