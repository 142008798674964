import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const MC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [seconds, setSeconds] = useState(0);

  const handleProgress = (secs) => {
    setSeconds(secs);
  };

  const navigatepage = () => {
    navigate("/A");
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="mt-2">
            <h6 className="watchtext">Watch This Video Fully</h6>
          </div>
          <div className="row mt-3">
            <div className="col-lg-9 mx-auto ms-auto">
              <ReactPlayer
                url="https://socialeagle.wistia.com/medias/vtpgz4xt11"
                className="videoalignment"
                // controls={true}
                onProgress={(e) => handleProgress(e.playedSeconds)}
              />
            </div>
          </div>
          <h6 className="webinartext mt-5">
            Schedule a <span className="freecall">FREE Expert Call</span> to
            Become a <span className="freecall">Digital Entrepreneur.</span>
          </h6>
          <h6 className="clickbutton">
            Click the Button below and&nbsp;
            <span className="book">Book a Call on the Next Page</span>
          </h6>
          <h6 className="clickbuttonend">
            (*Button will be enabled at the Middle of the video)
          </h6>
          <div className="alignment py-4">
            <button
              className="applybutton"
              style={parseFloat(seconds) < 480 ? { display: "none" } : {}}
              onClick={() => {
                navigatepage();
                window.fbq("track", "Lead");
              }}
            >
              SCHEDULE A CALL HERE
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default MC;
