import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";

const A = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.fbq("track", "Lead");
  }, []);

  return (
    <>
      <section>
        <div className="container">
          <div className="mt-5">
            <div className="alignment">
              <img src={logo} alt="Social Eagle" className="calenderlogo" />
            </div>
            <div className="mt-5">
              <iframe
                className="calenderstyle"
                title="TidyCal Calendar"
                src="https://tidycal.com/3l2n061/45-min-free-expert-cal"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default A;
